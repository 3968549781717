import React from 'react'
import { InlineWidget } from 'react-calendly'
import Layout from '../components/Layout'
import SEO from '../components/SEO'

const Schedule = () => {
  return (
    <>
      <Layout>
        <SEO
          title='Afspreken'
          description='Maak makkelijk en snel een afspraak in mijn agenda 📖'
        />
        <InlineWidget url='https://calendly.com/laurieusfit' />
      </Layout>
    </>
  )
}

export default Schedule
